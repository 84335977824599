// @media print { 
//     @page
//      {
//       size: A3 landscape;
//     }
//     mat-sidenav, mat-toolbar {
//       display: none !important;
//     }
//     header, footer, aside, nav, form, iframe, .menu, .hero, .adslot {
//       display: none;
//     }
  
//     .mat-drawer-content {
//       position: relative;
//       z-index: 1000;
//       display: inline;
//     }
  
//     .app-content {
//       background-color: transparent;
//       .app-title, .filter-card {
//         display: none !important;
//       }
//     }
  
//       #section-to-print, #section-to-print * {
//         visibility: visible;
//       }
        
//       #section-to-print {
//         position: absolute;
//         margin-left: auto;
//         margin-right: auto;
//         left: -240px;
//         right: 0;
//         text-align: center;
//         overflow: hidden;
//       }
    
//     body{
//       margin: 0;
//       background-color: #FFFFFF;
//       position: relative;
//     }
    
//     *{
//       box-sizing: border-box;
//     }
//   }


@media print {

  mat-sidenav,
  mat-toolbar,
  .app-content .app-menu-title,
  .app-content .app-title,
  .detail-form .header-label {
    display: none !important;
  }

  mat-sidenav-content#sidenav-content {
    margin-left: 0 !important;
  }

  .dash-row,
  .app-content {
    margin: 0 !important;
    padding: 0 !important;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

}