@use '../variables';

/* custom mat button */
.mat-stroked-button {
  border-radius: variables.$rounded-sm !important;
}

.mat-raised-button {
  border-radius: variables.$rounded-md  !important;
  color: variables.$color-primary;
}

.mat-flat-button {
  border-radius: variables.$rounded-sm !important;
}

.mat-button {
  &.outline {
    border: 1px solid variables.$color-primary;
  }
}