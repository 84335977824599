@use '../variables' as *;
$container-width: 90%;
$responsive-container-width: 90%;

.landing-page {
  position: relative;

  mat-sidenav-content {
    .app-root-content {
      height: 100vh;
      overflow: auto;

      .dashboard-toolbar {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        z-index: 2;
        position: absolute;
        padding: 0;
        top: 0;
        height: auto;
        background-color: transparent;

        .bg-color {
          position: absolute;
          inset: 0;
          background: linear-gradient(96.04deg, #0072ff 0%, #00c6ff 100%);
        }

        .logo-container {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #fff;

          span {
            font-size: 28px;
            font-weight: bold;
          }
        }

        &.blurred {
          background-color: transparent;
        }

        .dashboard__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 70px;
          margin: 0 auto;
          flex-wrap: wrap;
          width: $container-width;
          z-index: 1;
          padding-block: 0.5em;
          gap: 16px;

          h1 {
            margin: 0;
            padding: 0;
            font-weight: 400;
            flex: 1;
            margin-left: 32px;
          }

          .icon {
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        .header-action {
          display: flex;
          align-items: center;
          margin-right: 15px;

          .action {
            display: flex;
            gap: 8px;

            button {
              font-size: 16px;
              height: 42px;
              color: #ffffff;

              mat-icon {
                width: 32px;
                height: 32px;
                min-width: 32px;
              }
            }
          }

          .avatar {
            margin-left: 1em;

            .profile-menu-btn {
              border-radius: $rounded-sm;
              padding: 4px;

              .profile-info {
                @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
                  display: none;
                }
              }
            }
          }
        }

        .dash-row {
          display: block;
          width: 100%;
          overflow: auto;
        }

        .dash-row + .dash-row {
          margin: 0rem 1rem;
        }
      }
    }
  }

  .mat-form-field-flex {
    width: 100%;
  }

  .no-wrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    padding-right: 50px !important;
  }

  .app-container {
    height: 100vh;
    background: #fff;

    .example-sidenav {
      /* width */
      ::-webkit-scrollbar {
        width: 0px !important;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888 !important;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    }
  }

  #footer {
    position: relative;
    background: linear-gradient(96.04deg, #0072ff 0%, #00c6ff 100%);
    padding: 1.5rem 0;
    color: #fff;

    p {
      margin-bottom: 0;
    }

    .footer-wrapper {
      position: relative;
      width: $container-width;
      margin: 0 auto;

      .footer-content {
        width: $container-width - 20;
        margin: 0 auto;
      }

      .contact-wrapper {
        justify-content: space-between;
        flex-wrap: wrap;

        .address {
          mat-icon {
            min-width: 24px;
          }
        }

        .media {
          align-items: flex-end;

          button {
            border: 1px solid #fff;

            &:hover {
              background-color: $color-primary-darker;
            }
          }
        }

        .contact {
          color: rgba($color: #fff, $alpha: 0.8);
        }
      }
    }

    mat-divider {
      background-color: #fff;
    }
  }
}

@media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
  .landing-page {
    mat-sidenav-content {
      .app-root-content {
        .dashboard-toolbar {
          .dashboard__header {
            width: $responsive-container-width;
          }
        }
      }
    }

    #footer {
      .footer-wrapper {
        width: $responsive-container-width;

        .footer-content {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
  .landing-page {
    #footer {
      .footer-wrapper {
        .footer-content {
          width: 100%;
        }
      }
    }
  }
}
