@use '../variables' as var;

.chart-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding: 1rem;
  width: 100%;
  border-radius: var.$rounded-md;
}

.doughnut-chart {
  max-width: 250px;
  margin: auto;
}

.line-chart {
  width: 100%;
  margin-top: auto;
}