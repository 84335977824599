@use '../variables';

$title: 35px;
$content-space: 64px;
$title-size: 32px;
$desc-size: 15px;
$desc-responsive: 14px;
$input-search-height: 70px;

.home {
  max-width: 2000px;
  margin: 0 auto;
  background-color: inherit;

  .student-chart {
    height: 409px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
  }

  .stat-detail {
    width: 241px;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center !important;

    h1 {
      font-weight: bold;
    }

    h1,
    p {
      color: #24292f;
      padding-top: 20px;
      font-weight: bold;
      margin: 0;
    }

    .image,
    img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .title {
    // display: grid;
    // position: absolute;
    // top: 26px;
    // left: calc(30px + 12px);
    // z-index: 2;
    // font-size: 20px;
    // font-weight: 700;
    // letter-spacing: -0.05rem;

    // &::after {
    //   content: '';
    //   width: 50px;
    //   height: 5px;
    //   background: variables.$linear-gradient-primary;
    //   border-radius: 5px;
    // }
  }

  .flex-header {
    min-height: 44px;
    position: absolute;
    top: 13px;
    left: calc(30px + 12px);
    z-index: 3;
    display: flex;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.05rem;
    }

    .academic-filter {
      margin-left: 260px;

      &.mat-select {
        height: 44px;
        border: 1px solid #e7e7e7;
        color: #fff;

        &.academic-filter {
          line-height: 37px;
          min-width: 200px;
          font-size: 15px;
          font-weight: 400;
          border-radius: 25px;
          padding: 0.25em 0.5em;
        }

        mat-select-trigger {
          display: flex;
          align-items: center;
          color: #fff;

          .mat-icon {
            margin-right: 6px;
            color: variables.$color-secondary;
          }
        }
      }
    }
  }

  .mobile-filter {
    .academic-filter {
      &.mat-select {
        height: 44px;
        border: 1px solid #e7e7e7;
        color: white;

        &.academic-filter {
          line-height: 37px;
          min-width: 200px;
          font-size: 15px;
          font-weight: 400;
          border-radius: 25px;
          padding: 0.25em 0.5em;
        }

        mat-select-trigger {
          display: flex;
          align-items: center;
          color: #fff;

          .mat-icon {
            margin-right: 6px;
            color: variables.$color-secondary;
          }
        }
      }
    }
  }
}

.title-and-more {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .mat-card-header-text .mat-card-title {
    margin-bottom: 0;

    h1,
    h2,
    h3 {
      margin-bottom: 0;
    }
  }

  .mat-icon-button {
    &:hover {
      background-color: rgba(81, 78, 134, 0.1);
    }

    line-height: normal;
    min-width: auto;
    height: 35px;
    width: 35px;

    .mat-icon {
      height: auto;
      width: auto;
      line-height: normal;
      font-size: 21px;
    }
  }
}

.content-container {
  background-color: white;

  .title {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    padding: 50px 0;
  }

  .card-list {
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;

      .card {
        width: 900px;
        height: 70vh;
      }
    }
  }
}

.empty-home {
  height: 80vh;
  display: flex;
  justify-content: center;
  text-align: center;

  // align-items: center;
  .title-empty {
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
  }

  .sms-empty {
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    color: #00000099;
  }

  .avatar-default {
    border-radius: 15px;
    width: 40px;
    height: 40px;
    background-size: cover;
    object-fit: cover;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .content-text {
    background-color: rgb(255, 228, 228);
    padding: 20px 150px;
    border-radius: 10px;
  }

  .contents {
    display: flex;
    justify-content: center;
    align-items: center;

    .card-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
    }
  }

  .date {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 400;
  }

  .value {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .dete-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.board-container {
  min-width: 50vw;
  min-height: 50vh;
  max-width: 50vw;
  max-height: 50vh;
  margin: auto;
  display: flex;
  justify-content: center;
}

.board-item {
  text-align: center;
  border-bottom: 0.05px solid rgba(143, 143, 143, 0.2);
  padding: auto;

  &:last-child {
    border-bottom: none;
  }
}

// search school: search bar styles
.landing-page {
  .content-wrapper {
    .content {
      &.article {
        &__search-school {
          place-items: start;
          padding: 3em 0;
          min-height: 50em !important;

          h2 {
            position: relative;
            padding-left: 14px;
            margin-bottom: 0;

            &::after {
              content: '';
              width: 5px;
              height: 100%;
              border-radius: 50px;
              position: absolute;
              background-color: variables.$color-active;
              left: 0;
            }
          }

          .hide-filter {
            display: none;
          }

          .mat-mdc-form-field-subscript-wrapper {
            display: none;
          }

          mat-icon {
            height: 24px;
            width: 24px;
            min-width: 24px;
          }

          mat-icon[svgicon='max-location'], mat-icon[svgicon='pre-search'] {
            margin-inline: 12px;
          }

          .input-search {
            .mat-form-field-search {
              position: relative;

              &.mat-ripple {
                background-color: #fff;
              }

              &.mat-form-field-appearance-outline {
                .mat-form-field-infix {
                  min-height: $input-search-height;

                  input {
                    font-size: 16px;
                    min-height: inherit;
                    line-height: normal;
                  }
                }

                .mat-form-field-wrapper {
                  margin: 0 !important;
                  padding-block: 0 !important;

                  .mat-form-field-flex {
                    .mat-form-field-outline-start {
                      border-radius: 50px 0 0 50px !important;
                      width: 50px !important;
                      background-color: transparent !important;
                    }

                    .mat-form-field-outline-end {
                      border-radius: 0 50px 50px 0 !important;
                      background-color: transparent !important;
                    }
                  }
                }

                //!filter action at right side of input
                .filter-action {
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  .vertical-line {
                    height: 70%;
                    width: 1px;
                    background: variables.$athens-gray-color;
                  }

                  .mat-form-field-infix {
                    min-height: $input-search-height - 25;
                    width: 7em;
                  }

                  .mat-form-field-wrapper {
                    padding-inline: 16px;

                    .mat-form-field-flex {
                      .mat-form-field-outline-start {
                        border: none !important;
                        background-color: transparent !important;
                      }

                      .mat-form-field-outline-end {
                        border: none !important;
                        background-color: transparent !important;
                      }
                    }
                  }
                }
              }
            }
          }

          @media screen and (max-width: map-get($map: variables.$grid-breakpoints, $key: 'md')) {
            .input-search {
              .mat-form-field-search {
                &.mat-form-field-appearance-outline {
                  .mat-form-field-infix {
                    min-height: $input-search-height - 10;

                    input {
                      font-size: 14px;
                    }
                  }

                  .filter-action {
                    &.show-filter {
                      display: none;
                    }
                  }
                }
              }
            }
            .hide-filter {
              display: block !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .board {
    grid-template-columns: auto auto;
  }

  .board .board-item:nth-child(2n) {
    border-left: 0.05px solid rgba(143, 143, 143, 0.2);
  }

  .board .board-item:nth-child(n + 5) {
    border-bottom: none;
  }
}

@media screen and (min-width: 960px) {
  .board {
    grid-template-columns: auto auto auto;
  }

  .board-item:nth-child(3n) {
    border-left: 0.05px solid rgba(143, 143, 143, 0.2);
  }

  .board-item:nth-child(3n - 2) {
    border-right: 0.05px solid rgba(143, 143, 143, 0.2);
    border-left: none !important;
  }

  .board .board-item:nth-child(n + 4) {
    border-bottom: none;
  }

  .board .board-item:nth-child(3n + 2) {
    border-left: none;
  }
}
