@use '../mixin/flex' as *;
@use '../variables' as var;

// filter
.report-filter {
    form {
        display: grid;
        gap: 16px;
        align-items: center;

        .print-btn {
            margin-left: auto;
        }
    }

    .mat-form-field {

        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
            min-width: 18px;
            background-color: #fff;

        }

        .mat-form-field-wrapper {
            padding: 0;
        }
    }

    @media screen and (min-width: (map-get($map: var.$grid-breakpoints, $key: "sm"))) {
        form {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media screen and (min-width: (map-get($map: var.$grid-breakpoints, $key: "md"))) {
        form {
            grid-template-columns: repeat(4, minmax(0, 1fr));

            .generate-report-btn {
                width: fit-content;
            }
        }
    }
}

// report
.report {

    &__container {
        min-height: 400px;
        @include flexbox;
        @include flex-direction(column);
        align-items: center;
        place-content: center;
        background-color: var.$background-light-grey;
    }

    &__empty {
        @include flexbox;
        @include flex-direction(column);
        align-items: center;
    }

    &__dougnut-chart {
        .doughnut-chart {
            margin: unset;
        }
    }

    &__total-payments {
        .title-label {
            font-size: 16px;
        }

        .total {
            position: relative;

            .currency {
                position: absolute;
                top: -4px;
                left: 0;
                font-size: 16px;
            }

            .amount {
                margin-left: 14px;
                font-size: 26px;
                font-weight: 500;
            }
        }
    }

    .header-label {
        &.total-doughnut {
            margin-bottom: -16px;
        }
    }

}