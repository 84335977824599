@mixin flexbox {
    display: -webkit-box; // old
    display: -moz-box; // old
    display: -ms-flexbox; // ie
    display: -webkit-flex; // new
    display: flex; // new
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -ms-flex: $values;
    -webkit-flex: $values;
    flex: $values;
}

@mixin flex-order($order) {
    -webkit-box-ordinal-group: $order; // old
    -moz-box-ordinal-group: $order; // old
    -ms-flex-order: $order; // ie
    -webkit-order: $order; // new
    order: $order; // new
}

@mixin flex-direction($direction) {
    @if $direction == column {
        -webkit-flex-direction: vertical;
        -moz-flex-direction: vertical;
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
    } @else {
        -webkit-flex-direction: horizontal;
        -moz-flex-direction: horizontal;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
    }
}

.d-flex {
    @include flexbox;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.flex-direction-row {
    @include flex-direction(row);
}
.flex-direction-col {
    @include flex-direction(column)
}

.gap-8 {
    gap: 8px;
}
