@use '../variables' as *;

$card-rounded: 15px;

.announcement-wrapper {
  .announce {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }

  .announcement-thumbnail-container {
    overflow: hidden;
    width: 100%;
    height: 196px;
    border-radius: 15px;
    position: relative;
    border: 1px solid #f0f0f0;

    .announcement-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .date {
      position: absolute;
      right: 0;
      bottom: 0;

      .date-time {
        display: flex;
        margin-bottom: 15px;
        margin-right: 10px;

        .dated {
          margin-left: 10px;
          line-height: 17px;
          font-size: 14px;
          font-weight: 400px;
          line-height: 25px;
          color: #fff !important;
        }
      }
    }
  }

  @media screen and (min-width: 300px) and (max-width: 1240px) {
    // .announcement-thumbnail-container {
    //   width: fit-content;

    //   .announcement-thumbnail {
    //     width: 378px;
    //   }
    // }
  }

  .aspect-card {
    aspect-ratio: 0;

    .announs-info {
      .title {
        line-height: 39px;
        padding-top: 5px;
        font-size: 22px;
        font-weight: 600;
        color: #000000;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        color: #000;
      }

      .create_by {
        display: flex;
        margin-bottom: 10px;
      }

      .date {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-left: 10px;

        .dated {
          color: #8d98aa;
          margin-left: 5px;
        }
      }
    }

    .user {
      display: flex;

      .profile {
        margin-top: 12px;
        .avatar-default {
          border-radius: 20px;
          width: 40px;
          height: 40px;
          background-size: cover;
          object-fit: cover;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      .name {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        margin-top: 20px;

        .label {
          font-size: 14px;
          color: #8d98aa;
        }

        .value {
          font-size: 14px;
          font-weight: 600;
          color: #000;
          margin-left: 5px;
        }
      }
    }

    .title {
      line-height: 25px;
      padding-top: 5px;
      font-size: 18px;
      font-weight: 600;
      color: #000000;
    }

    .desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      color: #8b8b8b;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bg-image {
      max-width: 480px;
      height: 187px;
      border-radius: 15px;
    }

    .image {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      object-fit: cover;
      background-repeat: no-repeat;
    }

    .card {
      max-width: 480px;
      max-height: 365px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
    .aspect-card {
      flex-direction: column;
      aspect-ratio: 2/1;

      .bg-image {
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
      }

      h4.title {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: (map-get($map: $grid-breakpoints, $key: 'md')+200px)) {
    .announce {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: (map-get($map: $grid-breakpoints, $key: 'md')+100px)) {
    .announce {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: (map-get($map: $grid-breakpoints, $key: 'sm')+100px)) {
    .announce {
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr;
    }

    .bg-image {
      max-width: 480px;
      height: 200px !important;
      border-radius: 15px;
    }

    .announ-center-list {
      display: flex;
      align-items: center;
      gap: $spacing-sm;
      flex-wrap: wrap;
    }
  }
}

.announcement-content {
  .image-input-container {
    margin-top: 0.25rem;
    position: relative;
    height: 14em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background-light-grey;
    border-radius: $rounded-md;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: $rounded-md;
    border: 2px dashed $border-grey;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .thumbnail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    border-radius: 10px;
    border: 2px dashed #e8eaef;
    width: 100%;
    min-height: 100%;
    // padding: 16px;
    background-color: transparent;

    .thumbnail {
      width: 100px;
      max-height: 173px;
      border-radius: 10px;
      object-fit: cover;
    }

    .img-info {
      display: flex;
      flex-direction: column;

      .btn-delete {
        display: flex;
        align-items: center;
        gap: 4px;
        color: palevioletred;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .browse-file-trigger {
    color: $color-primary;
    cursor: pointer;
  }

  .btn-float-remove {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 99999;
  }

  .input-group {
    margin-bottom: 2em;
  }

  .creation-form {
    max-width: map-get($map: $grid-breakpoints, $key: 'lg');
  }

  // detail
  .description {
    margin-top: 1em;
  }

  .custom-error {
    color: red;
    font-size: 0.8em;

    &.of-quill {
      margin-top: -1em;
      padding-left: 1em;
    }
  }

  .announcement-thumbnail-container {
    overflow: hidden;
    width: 100%;
    height: 196px;
    border-radius: 15px;
    position: relative;
    border: 1px solid #cccccc;

    .announcement-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (min-width: map-get($map: $grid-breakpoints, $key: 'sm')) and (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    .announcement-thumbnail-container {
      width: fit-content;

      .announcement-thumbnail {
        width: 378px;
      }
    }
  }
}
