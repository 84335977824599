@use '../variables' as *;
$green: hsl(156deg 55% 31%);
@mixin card {
  background: rgba(35, 122, 87, 0.1);
  border: 0.947826px solid $color-white;
  border-radius: 10px;
}

@mixin btn-credential {
  &:not(:disabled) {
    background: rgba(255, 255, 255, 0.1);
  }
  border: 1px solid $color-primary;
}

@mixin background-image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
}

@mixin title {
  font-size: 16px;
  font-weight: bold;
}

.credential-container {
  width: 100%;
  height: 60vh;
  background: url(/assets/imgs/bg-student.svg);
  background-size: cover;

  .col-left {
    display: flex;
    justify-content: center;

    height: 100%;
    .hat {
      position: absolute;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      
      .content {
        margin: 30px 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;

          .name {
            font-size: 20px;
            font-weight: 700;
            color: $color-white;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .credential-form {
    width: 100%;
    background-color: #fff !important;
    z-index: 1;
    @include card();
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .logo {
        width: 89px;
        height: 89px;
        border-radius: 50%;
      }
    }
    .welcome {
      display: flex;
      justify-content: center;
      @include title;
      padding: 20px 0px;
      font-size: 24px;
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;

      mat-form-field {
        input {
          color: $color-black;
        }
      }

      .fg-pwd {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
        a {
          font-weight: 600;
          text-decoration: none;
          color: #0093E9;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .register {
        .account {
          color: #8F8F8F;
        }
        padding-top: 16px;
        display: flex;
        justify-content: center;
        a {
          font-weight: 600;
          padding-left: 5px;
          text-decoration: none;
          color: #0093E9;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .btn-credential {
      margin: auto;
      height: 40px;
      width: 100%;
      max-width: 140px;
      font-size: 16px;
      border-radius: 10px !important;
      background-color: #0093E9;
      &:not(:disabled):hover {
        cursor: pointer;
        background-color: #0093E9;
      }
      
      .btn-login {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    .col-left {
      width: 100%;
      position: absolute;
    }
    .credential-form {
      background: $color-white;
      width: 95%;
    }
  }
  .col-right {
    position: relative;
    width: 50%;
    float: right;
    height: 100vh;
    background-image: url(/assets/imgs/rectangle.svg);
    background-repeat: no-repeat;

    .person-phone {
      position: absolute;
      top: 50%;
      left: 20%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      max-width: 400px;
    }

    .merge-img {
      position: absolute;
      top: 60%;
      left: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      .globe {
        width: 180px;
        position: absolute;
        left: 103px;
        top: -1px;
      }
      .book {
        width: 175px;
        position: absolute;
        z-index: 1;
        top: 45px;
      }
    }
  }
}

// .lamp {
//   clear: both;
//   margin-left: auto;
//   width: 138px;
//   height: 100%;
//   background-image: url(/assets/imgs/lamp.png);
//   background-repeat: no-repeat;
//   background-size: 110px auto;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

.title {
  @include title();
}

.clearfix:after {
  content: '.';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
