@use '../variables' as *;

.attendance {
    .status {
        display: inline-flex;
        align-items: center;
        padding: $spacing-xs $spacing-md;
    
        &::before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius:50%;
          margin-right: 8px;
        }
    
        &.present,
        &.absent,
        &.permission {
          color: #00000066;
          font-weight: 400;
          font-size: 16px;
          border-right: 1px solid #0000000D;
          &:last-child {
            border-right: none;
          }
          &::before {
            background-color: $salem;
          }
        }
        &.absent {
          &::before {
            background-color: $bittersweet;
          }
        }
        &.permission {
          &::before {
            background-color: $deluge;
          }
        }
    
    }

    .filter-card{
        border-radius: 10px !important;
    }

    .semester-filter {
        min-width: 150px;
    }

    .border {
        border-radius: 10px;
    }

    mat-button-toggle-group {
        border: none;
        border-radius: 0;
    
        mat-button-toggle {
          border: 1px solid $border-grey;
          border-radius: 8px;
    
          button {
            width: 148px;
            height: 28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
    
            .mat-button-toggle-label-content {
              line-height: 0;
    
              .label {
                display: flex;
                justify-content: flex-start;
                align-items: center;
    
                span {
                  width: 8px;
                  height: 8px;
                  display: block;
                  border-radius: 100%;
                  margin-right: 12px;
                  border: 1px solid $color-grey;
                }
              }
            }
          }
        }
    
        .mat-button-toggle-checked.present {
          background-color: $salem;
        }
    
        .mat-button-toggle-checked.absent {
          background-color: $bittersweet;
        }
    
        .mat-button-toggle-checked.permit {
          background-color: $deluge;
        }
    
        .mat-button-toggle-checked.present,
        .mat-button-toggle-checked.absent,
        .mat-button-toggle-checked.permit {
          .label {
            color: #fff;
    
            span {
              background-color: #fff;
              border: none;
            }
          }
        }
    
      }
    
      @media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'sm')) {
        mat-button-toggle-group {
          display: flex;
          flex-direction: column;
    
          mat-button-toggle {
            margin-bottom: 8px;
          }
        }
    }
}