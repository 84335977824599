.template-back {
  table.table-certificate-back {
    width: 100%;
    border-radius: 0;
    border: 1px solid #000;

    thead {
      background-color: #d5e2bc;
      print-color-adjust: exact;
    }

    tr {
      &:first-child {
        th {
          &:first-child {
            border-bottom-left-radius: 0;
          }

          &:last-child {
            border-bottom-right-radius: 0;
          }
          border-bottom: 1px solid #000;
        }
      }

      th {
        color: #000;
        text-align: center;

        &:not(:first-of-type) {
          border-left: 1px solid #000;
        }

        font-size: 14px;
        text-transform: capitalize;
        font-weight: 700;
      }

      td {
        color: #000;

        &:not(:first-of-type) {
          border-left: 1px solid #000;
        }
      }

      th,
      td {
        padding: 0 8px;
        border-bottom-color: #000;

        .mat-radio-group {
          display: flex;
          padding: 0;
        }
      }

      &.mat-row:last-child {
        td {
          border-bottom-color: transparent;
        }
      }
    }
  }
}
