@use '../variables' as *;

.register-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -404px;

  .register-content {
    border: 1px solid rgb(238, 238, 238);
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 5px #bbd3ff;
    max-width: 1140px;

    .header-label {
      line-height: normal;
    }

    mat-stepper {
      .red-star {
        color: $color-light-red;
      }

      .event-none {
        pointer-events: none;
        cursor: none;
      }

      .member-card {
        border: 1px solid $black-squeeze-color;
        border-radius: 10px;
        padding: 1.2em 1em;
        cursor: pointer;
        box-shadow: none;
        transition: all 0.3 ease;

        &:hover {
          border: 1px solid transparent;
          box-shadow: 0 0 10px $iron-color;
        }

        &.active-card {
          border-color: $shamrock-color;
        }

        img {
          object-fit: cover;
          border-radius: 50px;
        }

        .text-blur {
          color: $aluminium !important;
        }

        .info {
          mat-icon {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
          }
        }

        .mat-checkbox {
          &.mat-checkbox-checked {
            &.mat-primary {
              .mat-checkbox-frame {
                border-color: $shamrock-color;
              }

              .mat-checkbox-background {
                background-color: $shamrock-color;
                border-radius: 100%;
              }
            }
          }

          .mat-checkbox-background {
            border-radius: 100%;
          }

          .mat-ripple {
            border-radius: 100%;
            scale: 0.8;
          }

          .mat-checkbox-frame {
            border-width: thin;
            border-radius: 100%;
          }

          .mat-checkbox-layout {
            transform: scale(1.3);
          }
        }
      }

      .image-drop-wrapper {
        .image-box-wrapper {
          position: relative;
        }

        .image-box {
          width: 250px;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-primary;
          cursor: pointer;

          img {
            width: inherit;
            height: inherit;
            object-fit: cover;
            box-shadow: 0 0 5px $mercury-color;
          }

          .button-remove {
            position: absolute;
            right: -18px;
            top: -18px;

            &.mat-mini-fab {
              scale: 0.6;
            }
          }

          &__border-dashed {
            border: 1px dashed $color-primary;

            .grid {
              place-items: center;
            }
          }

          &.disabled-upload {
            pointer-events: none;
            background-color: $background-grey;
            filter: grayscale(100%);
          }
        }
      }
    }

    .button-step {
      padding-inline: 20px;
    }
  }

  .input-name {
    .question_mark {
      position: relative;
      height: 22px;

      .help {
        font-size: 20px;
        color: #b4b4b4;
      }

      .help:hover {
        color: rgb(107, 107, 107);
      }
    }

    .message {
      position: absolute;
      width: 100%;
      top: 1.8em;
      left: 0;
      min-width: 15em;
      z-index: 1;

      .message-content {
        font-size: 12px;
        border-radius: 15px;
        width: inherit;
        background-color: #ffffff;
        z-index: 1;
        box-shadow: 0 2px 5px #e6e6e6;
        box-sizing: border-box;
        border: 1px solid #e8eaef;
        padding: 10px;
        word-break: break-all;

        ul {
          margin: 0;
        }
      }

      .creating-username {
        padding-left: 10px;
        font-weight: 500;
        background-color: #ffffff;
      }
    }
  }

  .auth_form {
    background-color: #b5e4ff;
    padding: 10px;
    border-radius: 10px;
    .mat-form-field {
      &.mat-form-field-appearance-outline {
        .mat-form-field-outline-start,
        .mat-form-field-outline-end {
          min-width: 18px;
          background-color: white !important;
        }
      }
    }
  }
}
