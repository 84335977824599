@use '../variables' as *;
@use '../mixin/flex' as *;

$color-val: rgba(83, 83, 95, 0.6);

.setting {
  .card {
    background: #ffffff;
    position: relative;
    border-radius: 15px;
    padding: 20px;

    .vertical {
      nav {
        .mat-tab-link-container {
          .mat-tab-list {
            width: 250px;

            .mat-tab-links {
              .mat-tab-label-active {
                font-weight: 600;
                color: #ffffff;
                height: 50px;
              }

              .mat-tab-link {
                font-size: 16px;
                opacity: 1;
                border-width: 0;
              }
            }
          }
        }
      }
    }

    .content {
      // padding: 20px;
      .label {
        padding: 6px 0px 0px 6px;
        font-weight: 500;
        font-size: 16px;
        color: rgba(83, 83, 95, 0.9);
      }
    }
  }

  .profile-container {
    display: flex;
    gap: 12px;
    align-items: center;
    .profile {
      img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .info {
      .name {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  h1 {
    // border-bottom: 1px solid $athens-gray-color;
    padding: 20px 20px;
    padding-top: 0;
    padding-left: 0;
  }

  .colored {
    color: $color-primary;
  }

  &.mat-ripple {
    cursor: pointer;
  }

  .title {
    padding-bottom: 0;
    font-size: 20px;
    line-height: 30px;
    .header {
      margin: 0 0 1px;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      color: rgba(83, 83, 95, 0.6);
    }
  }

  .creation-form {
    .mat-form-field-disabled {
      .mat-form-field-outline-end,
      .mat-form-field-outline-start {
        background-color: $alabaster;
      }

      .mat-select-value,
      .mat-select-arrow-wrapper,
      button {
        cursor: not-allowed;
      }

      input:disabled,
      input[disabled] {
        cursor: not-allowed !important;
      }

      select:disabled,
      select[disabled] {
        cursor: not-allowed !important;
      }
      textarea:disabled,
      textarea[disabled] {
        cursor: not-allowed !important;
      }
    }

    .disabled-attach {
      button {
        cursor: not-allowed;
      }
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    .vertical {
      display: flex;
      flex-direction: row;

      nav {
        padding-right: 16px;

        .mat-ink-bar {
          display: none;
        }

        .mat-tab-links {
          flex-direction: column;

          a {
            justify-content: flex-start;
          }

          .mat-tab-label-active {
            background: $linear-gradient-primary;
            border-radius: 10px;
          }
        }
      }

      .tab-wrapper {
        flex: 1;
        padding-left: 16px;
        border-left: 1px solid rgba($color: $color-primary, $alpha: 0.1);
      }

      .mat-tab-header {
        border-bottom: 0;
        margin-bottom: 0;
      }
    }

    .line-breaker {
      width: auto;
      display: flex;
      align-items: center;

      span {
        width: 2px;
        height: 16px;
        background-color: $athens-gray-color;
        display: block;
      }
    }
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    .line-clamp__1 {
      -webkit-line-clamp: 2;
    }

    .flex-columns {
      flex-direction: column;
    }
    .creation-form {
      padding: 0;
    }
  }

  .box {
    @include flexbox;
    @include flex-direction(row);
    // @include align-items(center);
    border: 1px solid rgba(116, 116, 116, 0.1);
    border-radius: 12px;
    padding: 12px 16px;
    gap: 16px;
    .icon {
      width: 34px;
      height: 34px;
    }
    .info {
      @include flexbox;
      @include flex-direction(column);
      .username {
        .val {
          color: $color-val;
        }
      }
    }
    // .group-btn {
    //   margin-left: auto;
    //   button {
    //     .mat-icon {
    //       path {
    //         stroke: #ffffff;
    //       }
    //     }
    //   }
    // }
  }

  .account {
    @include flexbox;
    @include flex-direction(column);
    gap: 16px;
  }
}
